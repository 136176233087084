import React from 'react';
import PropTypes from 'prop-types';
import { Section } from '@springrole/trivia-games';
import BasicAndFreeTrialComponent from './BasicAndFreeTrail';
import StandardAndProPlan from './StandardAndProPlan';
import styles from './styles.module.scss';

const ComparePlan = ({
  isMonetizedBasicOrFreeTrial,
  handleStripeRedirect,
  planResponse,
  platform,
  freeTrial,
  activeUsers,
  openCustomerPortal,
  dropDownChannelOptions,
  setSelectedChannels,
  selectedChannels,
  uniqueUsersCount,
  isCalculatingUniqueUsersCount,
  hasStripeSubscription,
  username,
  useremail,
  billingInfo,
  btnPlan,
  setBillingInfo,
  handleButtonClick,
  open,
  setOpen,
  channelBasedBilling
}) => {
  const { plan, planPrices } = planResponse;

  return (
    <Section>
      <div className={styles.comparePlanWrapper}>
        {plan === 'BASIC' || plan === null || freeTrial ? (
          <BasicAndFreeTrialComponent
            userPlan={plan}
            handleStripeRedirect={handleStripeRedirect}
            billingInfo={billingInfo}
            btnPlan={btnPlan}
            setBillingInfo={setBillingInfo}
            isMonetizedBasicOrFreeTrial={isMonetizedBasicOrFreeTrial}
            handleButtonClick={handleButtonClick}
            open={open}
            setOpen={setOpen}
            plans={planPrices}
            freeTrial={freeTrial}
            activeUsers={activeUsers}
            username={username}
            useremail={useremail}
            hasStripeSubscription={hasStripeSubscription}
            openCustomerPortal={openCustomerPortal}
            channelBasedBilling={channelBasedBilling}
            platform={platform}
            dropDownChannelOptions={dropDownChannelOptions}
            setSelectedChannels={setSelectedChannels}
            selectedChannels={selectedChannels}
            uniqueUsersCount={uniqueUsersCount}
            isCalculatingUniqueUsersCount={isCalculatingUniqueUsersCount}
          />
        ) : (
          <StandardAndProPlan
            userPlan={plan}
            planPrices={planPrices}
            handleButtonClick={handleButtonClick}
            activeUsers={activeUsers}
            platform={platform}
            openCustomerPortal={openCustomerPortal}
          />
        )}
      </div>
    </Section>
  );
};

ComparePlan.propTypes = {
  platform: PropTypes.string.isRequired,
  activeUsers: PropTypes.number.isRequired,
  planResponse: PropTypes.shape({
    plan: PropTypes.string,
    channelBasedBilling: PropTypes.bool,
    showNewBillingPage: PropTypes.bool,
    planPrices: PropTypes.shape({
      STANDARD: PropTypes.number,
      PRO: PropTypes.number,
      TERM: PropTypes.string
    }),
    customerInfo: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string
    }).isRequired
  }),
  openCustomerPortal: PropTypes.func.isRequired,
  dropDownChannelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  freeTrial: PropTypes.bool.isRequired,
  isMonetizedBasicOrFreeTrial: PropTypes.bool,
  setSelectedChannels: PropTypes.func.isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
  uniqueUsersCount: PropTypes.number.isRequired,
  isCalculatingUniqueUsersCount: PropTypes.bool,
  handleStripeRedirect: PropTypes.func.isRequired,
  hasStripeSubscription: PropTypes.bool,
  username: PropTypes.string.isRequired,
  useremail: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  channelBasedBilling: PropTypes.bool,
  btnPlan: PropTypes.string,
  setBillingInfo: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  billingInfo: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  }).isRequired
};

ComparePlan.defaultProps = {
  hasStripeSubscription: false,
  isCalculatingUniqueUsersCount: false,
  isMonetizedBasicOrFreeTrial: false,
  channelBasedBilling: false,
  planResponse: {
    plan: '',
    channelBasedBilling: '',
    showNewBillingPage: '',
    planPrices: {}
  },
  dropDownChannelOptions: [],
  btnPlan: ''
};

export default ComparePlan;

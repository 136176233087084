import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { ReactComponent as InfoIcon } from 'assets/images/home/infoIcon.svg';
import calculateTieredPricing from 'utils/utilities';
import { PlanCardTooltip } from 'components/Tooltip';
import styles from './styles.module.scss';

const StandardAndProPlan = ({ userPlan, activeUsers, planPrices, handleButtonClick }) => {
  const span = planPrices?.TERM || 'month';
  const baseUnits = userPlan === 'PRO' ? 25 : 29;

  const priceDetails = calculateTieredPricing(activeUsers, userPlan, span);

  const tooltipLabel =
    activeUsers <= baseUnits
      ? `${activeUsers} trivia Active Users \n\nmin. billing of $${priceDetails.totalCost}\nTotal: $${priceDetails.totalCost}`
      : `${activeUsers} trivia Active Users \n${priceDetails.tierDetails
          .map(
            (tier, index) =>
              `${tier.tierName}:   (${tier.applicableQty} users x $${
                // eslint-disable-next-line no-nested-ternary
                index === 0 ? (userPlan === 'PRO' ? '2' : '1') : tier.unitPrice
              }  ${index === 0 ? `x 12` : ''}) ${index === 0 ? 'x 20% off' : ''} = $${
                tier.tierCost
              }`
          )
          .join('\n')}\n\nTotal: $${priceDetails.totalCost}`;

  return (
    <>
      <div className={styles.anuallySection}>
        <div className={styles.savingsSection}>
          💰
          <div>
            {span === 'month' ? (
              <>
                Save <span>${priceDetails.savings}</span> by paying annually!!
              </>
            ) : (
              <>
                You are saving <span>${priceDetails.savings}</span> by paying annually!!
              </>
            )}
          </div>
        </div>
        <div className={styles.anuallyCard}>
          <div className={styles.yearlyDetails}>
            <div>
              {userPlan}
              <span className={styles.userPlanDetails}>(Yearly)</span>
            </div>
            <div>
              {userPlan === 'PRO' ? '49' : '29'}
              <span className={styles.userPlanDetails}>/month</span>
            </div>
          </div>
          <div className={styles.yearlyPlan}>
            <div className={styles.totalCost}>
              <span className={styles.crossed}>
                ${priceDetails.totalCostWithoutDiscount}
                /year
              </span>
              <span className={styles.offPercent}> 20% off</span>
            </div>
            <div className={styles.payingCost}>
              ${priceDetails.totalCost}
              <span className={styles.year}>/year</span>{' '}
              <span>
                <PlanCardTooltip multiline width={220} label={`${tooltipLabel}`} position='top'>
                  <InfoIcon />
                </PlanCardTooltip>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.finnalyText}>
        <div className={styles.exploreText}>
          Explore all plans, customizations, enterprise support & more features{' '}
          <button
            type='button'
            onClick={() =>
              window.open('https://support.springworks.in/portal/en/kb/trivia', '_blank')
            }
            className={styles.stripeButton}
          >
            here
          </button>
          .
        </div>
        <div className={styles.payAnually}>
          {span === 'month' ? (
            <Button onClick={() => handleButtonClick(userPlan.toUpperCase())}>Pay annually</Button>
          ) : (
            <Button className={styles.currentPlanBtn}>Current Plan</Button>
          )}
        </div>
      </div>
    </>
  );
};

StandardAndProPlan.propTypes = {
  userPlan: PropTypes.string.isRequired,
  activeUsers: PropTypes.number.isRequired,
  planPrices: PropTypes.shape({
    PRO: PropTypes.number.isRequired,
    STANDARD: PropTypes.number.isRequired,
    TERM: PropTypes.string.isRequired
  }),
  handleButtonClick: PropTypes.func.isRequired
};

StandardAndProPlan.defaultProps = {
  planPrices: {}
};

export default StandardAndProPlan;

import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import { useSelector } from 'react-redux';
import { useGetVirtualCoffeeAnalyticsQuery } from 'app/services/home';
import { useGetPlanQuery } from 'app/services/payment';
import localStorage from 'utils/localStorage';
import GamesOverview from 'components/GamesOverview';
import { identify, track } from 'utils/segment';
import { Modal, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { featureLaunchDate } from 'utils/utilities';
import dayjs from 'dayjs';
import history from 'createHistory';
import GameCards from './GameCards';
import styles from './styles.module.scss';
import Trend from './Trend';
import VcLimits from './VcLimits';

const Home = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [showModal, setShowModal] = useState(false);

  const platform = localStorage.getPlatform();
  const { data: virtualCoffee = { leftOut: 0 } } = useGetVirtualCoffeeAnalyticsQuery(undefined, {
    skip: platform === 'teams'
  });
  const { data: planDetails = { plan: '', hasSubscription: false } } = useGetPlanQuery();
  const userDetails = useSelector((state) => state.user);
  const freeTrial = planDetails.plan === null;
  const leftOutDaysFromWorkspaceCreation = dayjs().diff(dayjs(planDetails.createdAt), 'day');
  const leftOutDaysFromGameLaunch = dayjs().diff(dayjs(featureLaunchDate.taleTrail), 'day');
  const leftOutDays =
    30 - Math.min(leftOutDaysFromWorkspaceCreation, leftOutDaysFromGameLaunch, 30);
  const workspaceId = userDetails?.team?.id;
  const modalKey = `lastModalShown_${workspaceId}`;

  useEffect(() => {
    if (!userDetails.loading) {
      const { _id, loggedUsersCount = 0 } = userDetails;
      if (_id) {
        identify(_id, { 'Unique Logged In Users': loggedUsersCount });
        track('Visit Dashboard', {
          distinct_id: _id,
          Page: 'Home',
          Date: new Date(),
          'Unique Logged In Users': loggedUsersCount
        });
      }
    }
  }, [userDetails]);

  // Check Whether the modal is shown that current day
  useEffect(() => {
    if (workspaceId) {
      const lastShown = localStorage.get(modalKey);
      const today = dayjs().startOf('day');
      if (!(leftOutDaysFromWorkspaceCreation <= 30 || leftOutDaysFromGameLaunch <= 30)) {
        setShowModal(false);
        close();
        localStorage.clear(modalKey);
      } else if (!lastShown || dayjs(lastShown).isBefore(today)) {
        open();
        setShowModal(true);
        localStorage.set(modalKey, dayjs().format('YYYY-MM-DD'));
      }
    }
  }, [open, workspaceId]);

  return (
    <>
      <div>
        <Layout name='Home' className={styles.homeContentWrapper}>
          <div className={styles.welcomeText}>
            Welcome, {userDetails.user?.fullname?.trim().split(' ')[0]}!
          </div>
          <GamesOverview />
          {planDetails.plan === 'BASIC' && <Trend type='restricted' />}
          <GameCards planDetails={planDetails} />
          {platform === 'slack' && planDetails.plan === 'BASIC' && virtualCoffee.leftOut > 0 && (
            <VcLimits leftOut={virtualCoffee.leftOut} />
          )}
          {(userDetails.freeTrial ||
            ['STANDARD', 'PRO'].includes(userDetails.plan) ||
            planDetails.hasSubscription) && <Trend type='engagement' />}
        </Layout>
      </div>
      {showModal && platform !== 'teams' && !userDetails.loading && planDetails.plan !== 'PRO' && (
        <Modal
          opened={opened}
          onClose={close}
          title='Limited-time Access!'
          centered
          classNames={{
            content: styles.tailTrailModalContent,
            header: styles.tailTrailModalHeader,
            title: styles.tailTrailModalTitle
          }}
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3
          }}
          transitionProps={{ transition: 'fade', duration: 200 }}
        >
          {freeTrial ? (
            <div>
              <p>
                You&apos;re on day <span>{leftOutDaysFromWorkspaceCreation}</span> of your 7-day
                free trial with full access to our PRO features, including the thrilling Tale Trail
                game ✍️! <br /> <br />
                Post 7 days, enjoy an additional 23 days of limited access to weave stories with
                your colleagues 🚀.
              </p>
              <p>Upgrade to PRO to keep the excitement going without limits! ✨</p>
            </div>
          ) : (
            <div>
              <p>
                Your current {planDetails.plan} plan offers limited-time access to Tale Tail for 30
                days. You have <span>{leftOutDays}</span> days left to enjoy Tale Trail for free.
                <br />
                <br /> A fun and interactive story narration game. Make choices, shape the story,
                and have a blast!
              </p>{' '}
              To continue enjoying unlimited access to this never ending tale, consider upgrading to
              our PRO plan. Unlock your full potential today 🚀!
            </div>
          )}
          <Button className={styles.proUpgradeButton} onClick={() => history.push('/billing')}>
            Upgrade
          </Button>
        </Modal>
      )}
    </>
  );
};

export default Home;
